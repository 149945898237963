<template>
    <v-dialog v-if="!!photo" v-model="visible" persistent :max-width="width" :max-height="height"
        @click:outside="closeCallback">
        <v-card :color="$root.getTheme().background">
            <page-container class="ma-0 pa-0">
                <template slot="header">
                    <!-- 写真ヘッダ -->
                    <v-toolbar fixed dense :color="$root.getTheme().tertiary">
                        <v-container fluid class="ma-0 pa-0">
                            <v-row class="ma-0 pa-0">
                                <v-col cols="1">
                                    <v-tooltip bottom :color="$root.getTheme().tertiary">
                                        <template #activator="{ on }">
                                            <v-btn slot="activator" icon :disabled="photoIndex <= 0" v-on="on"
                                                @click="forward">
                                                <v-icon>mdi-chevron-left</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>前へ</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col cols="10" class="text-center">
                                    <v-text-field ref="photoNameInput" v-model="photo.name" placeholder="写真名を入力してください。"
                                        class="centered-input x-dense-input" single-line dense solo flat
                                        :rules="fileNameRule" @blur="updatePhoto" @change="editPhoto" />
                                </v-col>
                                <v-col cols="1">
                                    <v-tooltip bottom :color="$root.getTheme().tertiary">
                                        <template #activator="{ on }">
                                            <v-btn slot="activator" icon :disabled="photoIndex >= photos.length - 1"
                                                v-on="on" @click="next">
                                                <v-icon>mdi-chevron-right</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>次へ</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-btn icon justify="end" @click.stop="closeCallback">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <!-- 写真ツールバー -->
                    <v-toolbar fixed dense :color="$root.getTheme().quaternary">
                        <v-select v-model="photo.label" :items="labels" label="ラベル" item-text="no" return-object dense
                            outlined hide-details style="width: 50px;" @blur="updatePhoto" @change="editPhoto">
                            <template #item="{ item }">
                                <v-icon v-if="!!item.label_icon" :color="'#' + item.color_code">
                                    {{ item.label_icon }}
                                </v-icon>
                            </template>
                            <template #selection="{ item }">
                                <v-icon v-if="!!item.label_icon" :color="'#' + item.color_code">
                                    {{ item.label_icon }}
                                </v-icon>
                            </template>
                        </v-select>
                        <v-select v-model="photoTags" :items="tags" label="タグ" item-text="name" clearable return-object
                            dense outlined hide-details multiple style="width: 220px;" @blur="updatePhoto"
                            @change="editPhoto">
                            <template #selection="{ item }">
                                <v-chip :color="item.back_color_code" :text-color="item.fore_color_code" small>
                                    {{ photoTags.length == 1 ? item.name : (item.name.slice(0, 1) + '.') }}
                                </v-chip>
                            </template>
                        </v-select>
                        <v-text-field ref="pageNoInput" v-model="photo.page_no" label="ページ番号" dense outlined hide-details
                            style="width: 70px;" prefix="P" @blur="updatePhoto" @change="editPhoto" />

                        <v-spacer />
                        <v-divider vertical color="#A0A0A0" class="mr-1" />

                        <icon-with-text :text="photo.is_selected_for_page_spread ? '選定解除' : '選定'" :show-text="showText" icon
                            :icon-name="photo.is_selected_for_page_spread ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                            @click="updateSelectionStatus()" />
                        <icon-with-text text="AI処理" :show-text="showText" icon icon-name="mdi-head-cog-outline"
                            @click="doAiProc" />
                    </v-toolbar>
                </template>
                <template v-if="!!photo" slot="fill-contnts">
                    <v-container fluid>
                        <v-row class="ma-1">
                            <v-col cols="10" class="pa-1">
                                <!-- <v-img 
                                    :src="withRectangleMode && !!photo.visualized_file_path ? photo.visualized_file_path : photo.file_path" 
                                    contain
                                    :max-width="canvasWidth"
                                    :max-height="canvasHeight"
                                /> -->
                                <drawing-rect-canvas ref="drawingRectCanvasVue" :src="photo.file_path"
                                    :faces="withRectangleMode ? (photo.photo_faces.filter((pf) => pf.is_highlighting)) : []"
                                    :canvas-height="canvasHeight" />
                            </v-col>
                            <v-col cols="2" class="pa-1">
                                <v-card>
                                    <v-card-title class="pa-1">
                                        検出顔({{ photo.photo_faces.length }})
                                    </v-card-title>
                                    <v-toolbar fixed dense :color="$root.getTheme().quaternary" class="mx-0 px-0">
                                        <v-btn-toggle v-model="shouldSelectAll" color="accent" dense group>
                                            <v-tooltip v-if="shouldSelectAll" bottom>
                                                <template #activator="{ on }">
                                                    <v-btn slot="activator" icon :value="false" v-on="on"
                                                        @click="selectAllPhotoFaces">
                                                        <v-icon>mdi-select</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>全選択</span>
                                            </v-tooltip>
                                            <v-tooltip v-else bottom>
                                                <template #activator="{ on }">
                                                    <v-btn slot="activator" icon :value="true" v-on="on"
                                                        @click="disselectAllPhotoFaces">
                                                        <v-icon>mdi-select-all</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>全選択解除</span>
                                            </v-tooltip>
                                        </v-btn-toggle>

                                        <v-tooltip bottom>
                                            <template #activator="{ on }">
                                                <v-btn slot="activator" icon v-on="on" @click="deletePhotoFaces">
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>選択した検出顔の削除</span>
                                        </v-tooltip>
                                    </v-toolbar>
                                    <v-card-text v-if="photo.photo_faces.length == 0" class="px-1">
                                        <v-icon>error</v-icon>
                                        未検出
                                    </v-card-text>
                                    <v-card-text v-else class="px-1">
                                        <v-container class="ma-0 pa-0  overlay-scrollable overflow-y-auto"
                                            :style="{ 'max-height': photoFaceListHeight + 'px' }">
                                            <v-row class="ma-0 pa-0" dense>
                                                <v-col v-for="pf in photo.photo_faces" :key="pf.id" cols="4" xl="4" lg="6">
                                                    <photo-face-card :id="pf.id" :face="pf.face"
                                                        :image-src="pf.detected_image_path"
                                                        :laughing-rate="pf.laughing_rate"
                                                        :is-highlighting="pf.is_highlighting"
                                                        :click-callback="clickPhotoFace"
                                                        :similarity-score="pf.similarity_score" :edit-icon="true"
                                                        class="flex-center"
                                                        @edit-icon-clicked="toggleEditPhotoFaceDialog(pf)" />
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row class="pa-1">
                            <v-col cols="5" class="pa-1" style="vertical-align: middle!important;">
                                <span>撮影日時：{{ formatDatetime(photo.shooting_time) }}</span>
                                <!-- <span>撮影日時：{{ formatDatetime(photo.shooting_time) }}撮影シーン：{{ shootingSceneName }}</span>-->
                            </v-col>
                            <v-col cols="5" class="pa-1">
                                <v-switch ref="withRectangleSwitch" v-model="withRectangleMode" label="顔位置を表示"
                                    :disabled="!photo.visualized_file_path" dense hide-details class="mt-0 pt-0 float-right"
                                    color="red" :ripple="false" />
                            </v-col>
                            <v-spacer />
                        </v-row>
                    </v-container>
                    <edit-photo-face-dialog v-model="editPhotoFaceDialog" :faces="faces" :photo-face="editingPhotoFace"
                        :face-ids-existing-in-photo="existingFaceIds"
                        @face-selected="(face) => editParentFaceOfPhotoFace(face)" />
                </template>
            </page-container>
        </v-card>
    </v-dialog>
</template>

<script>
import http from '../../services/http';
import urlUtil from '../../utils/url';
import monet from 'monet';
import { awaitJobDone } from '../../services/awaitJobDone';

export default {
    // mixins: [leaveConform],
    props: {
        albumId: {
            default: 0,
            type: [String, Number],
        },
        photoId: {
            default: 0,
            type: [String, Number],
        },
        photos: {
            default: () => [],
            type: Array[Object],
        },
        faces: {
            default: () => [],
            type: Array[Object],
        },
        labels: {
            default: () => [],
            type: Array[Object],
        },
        tags: {
            default: () => [],
            type: Array[Object],
        },
        shootings: {
            default: () => [],
            type: Array[Object],
        },
        pageSpreadMode: {
            default: false,
            type: Boolean,
        },
        visible: {
            default: false,
            type: Boolean,
        },
        slidePhotoCallback: {
            default: () => { },
            type: Function,
        },
        updatePhotoCallback: {
            default: () => { },
            type: Function,
        },
        closeCallback: {
            default: () => { },
            type: Function,
        },
    },
    components: {
        'photo-face-card': require('../PhotoFaces/PhotoFaceCard.vue').default,
        'drawing-rect-canvas': require('../Faces/DrawingRectCanvas.vue').default,
        'icon-with-text': require('../Icons/IconWithText.vue').default,
        'edit-photo-face-dialog': require('./EditPhotoFaceDialog.vue').default,
    },
    data() {
        return {
            photoIndex: 0,
            photo: undefined,
            photoTags: [],
            shooting: undefined,
            isEdited: false,
            withRectangleMode: true,
            shouldSelectAll: false,
            showText: true,
            bad_chars: '\\/:*?"<>|',
            fileNameRule: [
                v => !!v || '入力してください。',
                v => !!v &&
                    !this.bad_chars.split('').some((char) => {
                        return v.includes(char);
                    })
                    || 'ファイル名に使用出来ない文字が含まれています',
            ],
            editPhotoFaceDialog: false,
            editingPhotoFace: {},
        };
    },
    computed: {
        width() {
            return innerWidth - (innerWidth / 10);
        },
        height() {
            return innerHeight - (innerHeight / 10);
        },
        canvasWidth() {
            return this.canvasHeight * 16 / 9;
        },
        canvasHeight() {
            return this.height - 200;
        },
        photoFaceListHeight() {
            return this.height - 320;
        },
        shootingSceneName() {
            return monet.Maybe.fromNull(this.photo.shooting_scene).map(s => s.name).orSome('');
        },
        existingFaceIds() {
            return this.photo.photo_faces.map((pf) => {
                return pf.face.id;
            });
        },
    },
    mounted() {
        document.onkeydown = this.keydown;
    },
    methods: {
        initialize() {
            monet.Maybe
                .fromNull(this.photos.findIndex(p => p.id === this.photoId))
                .forEach(ix => {
                    this.photoIndex = ix;
                    this.photo = JSON.parse(JSON.stringify(this.photos[ix])); // deep copy
                    this.photoTags = this.photo.photo_tags.map(pt => pt.tag);
                    this.isEdited = false;
                    this.photo.visualized_file_path += '?' + Math.random(); // 画像再描画のためにパスを変更
                    if (!this.shouldSelectAll) this.selectAllPhotoFaces();
                });
        },
        forward() {
            monet.Maybe
                .fromNull(this.photoIndex > 0 ? this.photoIndex - 1 : null)
                .forEach(ix => this.slidePhotoCallback(this.photos[ix].id));
        },
        next() {
            monet.Maybe
                .fromNull((this.photoIndex < this.photos.length - 1) ? (this.photoIndex + 1) : null)
                .forEach(ix => this.slidePhotoCallback(this.photos[ix].id));
        },
        movePhoto() {
            (this.pageSpreadMode
                ? monet.Maybe.fromNull(this.shooting).toValidation('撮影行事を選択してください。')
                : monet.Maybe.fromNull(this.pageSpread).toValidation('ページ構成を選択してください。'))
                .map(x => {
                    let url = urlUtil.addQueryParamIfDefined('photos/move', 'photo_directory_id', x.photo_directory_id);
                    url = urlUtil.addQueryParamIfDefined(url, 'ids[]', this.photo.id);
                    return url;
                })
                .cata(
                    err => this.showError(err, '写真移動エラー'),
                    url => http.put(url)
                        .then(() => {
                            this.updatePhotoCallback(this.photo.id, this.findNextPhotoId());
                            this.closeCallback();
                        })
                        .catch(error => {
                            if (error === 'unauthorized') return;
                            this.showError(error, '写真移動エラー');
                        })
                );
        },
        editPhoto() {
            this.isEdited = true;
        },
        doOcrProc() {
            http
                .put('photos/ai_ocr_rename', {
                    shooting_id: this.photo.shooting_id,
                    photo_ids: [this.photoId],
                })
                .then((res) => {
                    if (res.data.length == 0) {
                        this.showInfo('写真の札番号リネームが完了しました。', '札番号リネーム');
                    } else {
                        this.showError('札番号読み取りに失敗しました', '札番号リネームエラー');
                    }
                    this.updatePhotoCallback(this.photo.id, this.findNextPhotoId());
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '札番号リネームエラー');
                });
        },
        doAiProc() {
            http
                .post('photo_faces/ai_proc', {
                    album_id: this.albumId,
                    photo_ids: [this.photoId],
                    face_ids: [],
                }) // 顔検出
                .then((res) => {
                    const jobId = res.data.job_id;

                    awaitJobDone(jobId, true).then(() => {
                        // 物体・シーン検出
                        this.showInfo('写真のAI処理が完了しました。', 'AI処理');
                        this.updatePhotoCallback(this.photo.id, this.findNextPhotoId());
                    }).catch((error) => {
                        this.showError(error, 'AI処理エラー');
                    });
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, 'AI処理エラー');
                });
        },
        updatePhoto() {
            if (!this.isEdited) {
                return;
            }
            http
                .put('photos/update/' + this.photo.id, {
                    name: this.photo.name,
                    label_id: this.photo.label.id,
                    photo_tags: this.photoTags.map(t => t.id),
                    page_no: this.photo.page_no,
                    is_selected_for_page_spread: this.photo.is_selected_for_page_spread,
                    updated_at: this.photo.updated_at,
                })
                .then(() => this.updatePhotoCallback(this.photo.id, this.findNextPhotoId()))
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '写真登録エラー');
                });
        },
        updateSelectionStatus() {
            this.isEdited = true;
            this.photo.is_selected_for_page_spread = !this.photo.is_selected_for_page_spread;

            this.updatePhoto();
        },
        findNextPhotoId() {
            return monet.Maybe
                .fromNull(this.photoIndex + 1 !== this.photos.length
                    ? this.photos[this.photoIndex + 1] // 末尾でなければ次の写真ID
                    : null); // それ以外は none
        },
        keydown(e) {
            monet.Maybe.fromNull(this.photo)
                .forEach(() => {
                    if ((this.$refs.photoNameInput && this.$refs.photoNameInput.isFocused)
                        || (this.$refs.pageNoInput && this.$refs.pageNoInput.isFocused)
                        || (this.$refs.withRectangleSwitch && this.$refs.withRectangleSwitch.isFocused)
                        || !this.visible
                    ) {
                        return;
                    }
                    console.log(e.key);
                    switch (e.key) {
                        case 'ArrowRight':
                            this.next();
                            break;
                        case 'ArrowLeft':
                            this.forward();
                            break;
                        default:
                            if (!isNaN(Number(e.key))) {
                                this.photo.label.id = this.labels.find(l => l.no == Number(e.key)).id;
                                this.isEdited = true;
                                this.updatePhoto();
                            }
                            break;
                    }
                });
        },
        clickPhotoFace(photoFaceId) {
            monet.Maybe.fromNull(this.photo.photo_faces.find(pf => pf.id === photoFaceId))
                .forEach(pf => {
                    pf.is_highlighting = !pf.is_highlighting; // トグル

                    // const highlightPhotoFaceIds = this.photo.photo_faces.filter(pf => pf.is_highlighting).map(pf => pf.id);

                    // TODO 該当座標のハイライト
                });
        },
        selectAllPhotoFaces() {
            this.photo.photo_faces.forEach(pf => pf.is_highlighting = true);
        },
        disselectAllPhotoFaces() {
            this.photo.photo_faces.forEach(pf => pf.is_highlighting = false);
        },
        addPhotoFace() {
            if (this.$refs.drawingRectCanvasVue.adjustedSelectedPosition == null) {
                this.showError('検出顔が未選択です');
                return;
            }
            var url = 'photo_faces';
            // 調整済み座標
            var top_left = Object.assign({}, this.$refs.drawingRectCanvasVue.adjustedSelectedPosition.top_left);
            var bottom_right = Object.assign({}, this.$refs.drawingRectCanvasVue.adjustedSelectedPosition.bottom_right);
            var data = new FormData();
            data.append('album_id', this.albumId);
            data.append('photo_id', this.photo.id);
            data.append('photo_path', this.photo.file_path);
            data.append('coordinate', JSON.stringify({
                'top_left': top_left, 'bottom_right': bottom_right
            }));

            http
                .post(url, data)
                .then(() => {
                    this.showInfo('検出顔追加が完了しました', '検出顔追加');
                    this.updatePhotoCallback(this.photo.id);
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '検出顔追加に失敗しました。');
                });
        },
        deletePhotoFaces() {
            var selected_photo_faces = this.photo.photo_faces.filter((pf) => {
                return pf.is_highlighting == 1;
            });
            if (selected_photo_faces.length == 0) {
                this.showError('検出顔が未選択です');
                return;
            }

            var url = 'photo_faces';
            selected_photo_faces.forEach((pf) => {
                url = urlUtil.addQueryParamIfDefined(url, 'photo_face_ids[]', pf.id);
            });
            http
                .delete(url)
                .then(() => {
                    this.showInfo('検出顔削除が完了しました。', '検出顔削除');
                    this.updatePhotoCallback(this.photo.id);
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '検出顔削除に失敗しました。');
                });
        },
        editParentFaceOfPhotoFace(faceId) {
            http.put('photo_faces/' + this.editingPhotoFace.id, {
                ...this.editingPhotoFace,
                face_id: faceId,
                similarity_score: 1,
            })
                .then(() => {
                    this.showInfo('検出顔の顔マスタ修正が完了しました。', '顔マスタ修正');
                    this.updatePhotoCallback(this.photo.id);
                })
                .catch(error => {
                    if (error === 'unauthorized') return;
                    this.showError(error, '検出顔の顔マスタ修正に失敗しました。');
                });
        },
        toggleEditPhotoFaceDialog(photoFace) {
            if (this.editPhotoFaceDialog) {
                this.editingPhotoFace = {};
                this.editPhotoFaceDialog = false;
            } else {
                this.editingPhotoFace = Object.assign({}, photoFace);
                this.editPhotoFaceDialog = true;
            }
        },
    },
};
</script>

<style>
.photo-thumbnail-card div.photo-name {
    text-align: center;
}

.centered-input input {
    text-align: center;
}

.x-dense-input div.v-input__control {
    height: 48px !important;
}

.x-dense-input div.v-input__slot {
    height: 48px !important;
}

/* .x-dense-input div.v-text-field__details {
  height: 16px!important;
}
.x-dense-input input {
  height: 16px!important;
} */
</style>
